import React from "react"
import bg from "../assets/wineabroad/landscape.jpg"
import brand from "../images/logo-wc.png"
const MaintenancePage = () => (
  <div className="maintenance-main">
    <img src={brand} alt="wineabroad-brand-logo" width="250px" />
    <p>
      Thank you for your visit this Website is currently under construction.
    </p>
    <p>Official Launch in January 2021.</p>
    <p>Looking forward to talking to you then.</p>
    <p>Adrien @ Wine Abroad </p>
    <style JSX>
      {`
          .maintenance-main {
            width: 100vw;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
            
          }

          .maintenance-main::before {
            content: "";
            position: absolute;
            top: 0; left: 0;
            z-index: -1;
            width: 100%; height: 100%;
            background: url(${bg}) no-repeat;
            background-position: center;
            background-size: cover;
            filter: blur(2px);
          }

          .maintenance-main > img {
            margin-bottom: 40px;
          }

          .maintenance-main p {
            font-size: 1.2em;
            text-align: center;
            color: #fff;
          }
        `}
    </style>
  </div>
)

export default MaintenancePage
